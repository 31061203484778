export const Time = {
  Microsecond: 1 / 1000,
  Microseconds: 1 / 1000,
  Millisecond: 1,
  Milliseconds: 1,
  Second: 1000,
  Seconds: 1000,
  Minute: 60 * 1000,
  Minutes: 60 * 1000,
  Hour: 60 * 60 * 1000,
  Hours: 60 * 60 * 1000,
  Day: 24 * 60 * 60 * 1000,
  Days: 30 * 24 * 60 * 60 * 1000,
}

export const DocumentTypeValues = {
  CPF: 1,
  ID: 3,
  DNI: 6,
  RUT: 5,
  CIE: 2,
  PASSPORT: 4,
  BIRTH_CERTIFICATE: 0,
}

export const DocumentMasks = {
  CPF: "###.###.###-##",
}

export const DocumentSide = {
  Back: "back",
  Front: "front",
}

export const STATUS_RESERVATION = {
  TO_CONFIRM: 0,
  CONFIRMED: 1,
  CHECKIN: 2,
  CHECKOUT: 3,
  PENDING: 4,
  NO_SHOW: 5,
  CANCELLED: 6,
  WAITING_LIST: 7,
}

export const LANGS = [
  { text: "English", value: "en", flag: "us", full: "en-US" },
  { text: "Português", value: "pt", flag: "br", full: "pt-BR" },
  { text: "Spanish", value: "es", flag: "es", full: "es-ES" },
]

export const addressMask = (maxSize) => {
  let result = ""

  for (let i = 0; i < maxSize; i++) {
    result += "S"
  }

  return result
}
